import { render, staticRenderFns } from "./leaveMsg.vue?vue&type=template&id=083a4c74&scoped=true"
import script from "./leaveMsg.vue?vue&type=script&lang=js"
export * from "./leaveMsg.vue?vue&type=script&lang=js"
import style0 from "./leaveMsg.vue?vue&type=style&index=0&id=083a4c74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "083a4c74",
  null
  
)

export default component.exports